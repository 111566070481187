:root {
  --black: #000;
  --mute: #B9B8B8;
  --main-color: #3DB7A5;
  --placeholder: #8E8E8E;
  --label: #263A43;
  --white: #fff;
  --slate: #708090;
  --table: #111827;
}


* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.form-control:focus {
  box-shadow: none;
}

.form-check:focus-visible {
  outline: none !important;
}

body {
  /*    background: #ECF8F6;*/
  width: 100%;
  height: 100%;
  background-size: cover;
  margin: 0;
  height: 100vh;
}

.bg-slate {
  background: var(--slate);
  border-radius: 10px;
}

.fw-600 {
  font-weight: 600;
}

/* Common Auth Page Css */

.text-black {
  color: var(--black);
}

.text-slate {
  color: var(--slate);
}

.text-mute {
  color: var(--mute);
}

.thm-color {
  color: var(--main-color);
}

h2.text-black {
  font-weight: 900;
}

.text-label {
  color: var(--label);
}

.common .form-control {
  border: 1px solid #B1B1B1 !important;
  border-radius: 6px;
  height: 55px;
}

.common .form-control:focus {
  box-shadow: none;
  border: 1px solid var(--main-color) !important;
}

.color {
  color: var(--placeholder) !important;
}

.common .form-control::placeholder {
  color: var(--placeholder);
}

.bg-login {
  background: url(../public/images/login-bg.png);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-login {
  background: var(--main-color);
  padding: 20px 10px;
  font-weight: 800;
  color: white;
  font-size: 17px;
  border: 1px solid var(--main-color);
  box-shadow: 0px 4px 30px 0px #263A4326;
}

.btn-login:hover {
  border: 1px solid var(--main-color);
  background: white;
  color: var(--main-color);
}

.profile-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.community-dropdown {
  border: 1px solid #708090;
  border-radius: 10px;
  color: var(--main-color);
  font-weight: bold;
  padding: 8px 14px;
  margin-right: 20px;
  text-transform: capitalize;
}

.ref-image input.form-control {
  border: 0.5px solid #fff !important;
  padding: 8px 16px;
  border-radius: 10px;
}

.ref-image .input-group-text {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 8px 16px;
}

.input-group.br {
  border: 1px solid #708090 !important;
  border-radius: 10px;
  margin-left: 20px;
}

.table-card {
  box-shadow: 0px 0px 10px 0px #0000001A;
  border-radius: 15px;
}

.common-table thead {
  background: var(--main-color) !important;
  color: var(--white);
  border-radius: 15px !important;
}

.common-table thead tr th {
  padding-top: 16px;
  padding-bottom: 16px;
}

.common-table>:not(caption)>*>* {
  border: 0px !important;
}

.common-table>tbody>tr:nth-of-type(odd)>* {
  box-shadow: none !important;
  color: var(--table) !important;
  background-color: white !important;
}


.common-table>tbody>tr:nth-of-type(even)>* {
  box-shadow: none !important;
  background-color: #F9FAFB !important;
  color: var(--table) !important;
}

.common-table .btn-green {
  border: 1px solid var(--main-color);
  width: 40px;
  height: 40px;
  padding: 0px;
}

.common-table .btn-red {
  border: 1px solid #FF0000;
  width: 40px;
  height: 40px;
  padding: 0px;
}

.btn-main {
  border: 1px solid var(--main-color);
  background: var(--main-color);
  color: var(--white);
  padding: 12px 22px;
  border-radius: 8px;
  font-weight: 700;
}

.btn-pink {
  border: 1px solid #FF91A4;
  background: #FF91A4;
  color: var(--white);
  padding: 12px 22px;
  border-radius: 8px;
  font-weight: 700;
}

.text-pink {
  color: #FF91A4;
  text-decoration-color: #FF91A4;
  font-weight: bold;
}

.Shadow {
  box-shadow: 0px 23px 24px -10px #3DB7A55E;
}

.common-form .form-control {
  border: 1px solid #DDDDDD !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  border-radius: 8px;
}

.common-form .form-label {
  color: #333F51;
  font-weight: 600;
}

#file-input {
  display: none;
}

#custom-button {
  background-color: DDDDDD;
  color: #fff;
  padding: 10px 15px;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#file-name {
  margin-left: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  border: 1px solid var(--main-color);
  background: var(--main-color);
  color: var(--white);
  padding: 12px 22px;
  border-radius: 8px;
  font-weight: 700;
}

.nav-pills .nav-link {
  border: 1px solid #D9D9D9;
  background: #D9D9D9;
  color: var(--slate);
  padding: 12px 22px;
  border-radius: 8px;
  font-weight: 700;
  margin-right: 10px !important;
}


.layout {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.layout.has-sidebar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.layout .header {
  -webkit-transition: width, 0.3s;
  transition: width, 0.3s;
  height: 64px;
  min-height: 64px;
  position: relative;
}

.layout .sidebar {
  width: 280px;
  min-width: 280px;
  -webkit-transition: width, left, right, 0.3s;
  transition: width, left, right, 0.3s;
}

.layout .sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

.layout .footer {
  height: 64px;
  min-height: 64px;
}

.layout .content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.layout .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
  display: none;
}

.layout .sidebar-toggler {
  display: none;
}

.layout.fixed-sidebar {
  height: 100%;
}

.layout.fixed-sidebar .sidebar {
  height: 100%;
  overflow: auto;
}

.layout.fixed-sidebar .sidebar~.layout {
  height: 100%;
  overflow: auto;
}

.layout.fixed-header .header {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.layout.fixed-header .header~.layout,
.layout.fixed-header .header~.content {
  margin-top: 64px;
}

.layout.fixed-header.fixed-sidebar .header {
  width: calc(100% - 280px);
}

.layout.fixed-header.fixed-sidebar .sidebar.collapsed~.layout .header {
  width: calc(100% - 80px);
}

.layout.rtl {
  direction: rtl;
}

.layout .navbar {
  /*    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;*/
}

.layout .nav-item .nav-link svg {
  vertical-align: bottom;
}

.layout .navbar-toggler {
  box-shadow: none;
}

.layout .nav-item .nav-link {
  /*  font-weight: bold;
  color: #000;*/
  margin: auto 10px !important;
}

/*.layout .nav-item .nav-link:hover {
  color: #0092D0;
}*/

.layout .dropdown-toggle span {
  color: black;
  font-weight: bold;
}

.aos {
  font-weight: bold;
  color: #00519C;
}

.layout .menu-item a:hover {
  background: #2ca392;
  font-weight: 600;
}


.layout .menu-item-wo-hover a {
  /* background: var(--white); */
  font-weight: 600;
  font-size: 15px;
}


.layout .sidebar .menu .menu-item-wo-hover a .menu-title {
  color: var(--main-color);
}

.layout .sidebar .menu .menu-item-wo-hover a .menu-icon {
  color: #fff;
  width: 35px;
  min-width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border-radius: 2px;
  transition: color 0.3s;
}








.layout {
  z-index: 1;
}

.layout .header {
  display: flex;
  align-items: center;
  padding: 20px;
}

.layout .content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: #ECF8F6;
}

.layout .footer {
  text-align: center;
  margin-top: auto;
  margin-bottom: 20px;
  padding: 20px;
}

.sidebar {
  color: #7d84ab;
  overflow-x: hidden !important;
  position: relative;
}

.sidebar::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.sidebar:hover::-webkit-scrollbar-thumb {
  background-color: #3DC8AF;
}

.sidebar::-webkit-scrollbar {
  width: 3px;
  background-color: #3DC8AF;
}

.sidebar .image-wrapper {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
}

.sidebar .image-wrapper>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.sidebar.has-bg-image .image-wrapper {
  display: block;
}

.sidebar .sidebar-layout {
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--main-color);
  z-index: 2;
  box-shadow: 15px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.sidebar .sidebar-layout .sidebar-header {
  height: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.sidebar .sidebar-layout .sidebar-header>span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sidebar .sidebar-layout .sidebar-content {
  flex-grow: 1;
  padding: 10px 0;
}

.sidebar .sidebar-layout .sidebar-footer {
  height: 230px;
  min-height: 230px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.sidebar .sidebar-layout .sidebar-footer>span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes swing {

  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }
}

.layout .sidebar .menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.layout .sidebar .menu .menu-header {
  font-weight: 600;
  padding: 10px 25px;
  font-size: 0.8em;
  letter-spacing: 2px;
  transition: opacity 0.3s;
  opacity: 0.5;
}

.layout .sidebar .menu .menu-item a {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 12px;
  color: #fff;
  margin: 0px 12px;
  text-decoration: none;
  border-radius: 10px;
}



.layout .sidebar .menu .menu-item-wo-hover a {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 12px;
  color: #fff;
  margin: 0px 0px;
  text-decoration: none;
  border-radius: 10px;
}




.layout .sidebar .menu .menu-item a .menu-icon {
  width: 35px;
  min-width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border-radius: 2px;
  transition: color 0.3s;
  /* color: #000; */
}

.layout .sidebar .menu .menu-item a .menu-icon i {
  display: inline-block;
}

.layout .sidebar .menu .menu-item a .menu-title {
  font-size: 15px;
  overflow: hidden;
  /* text-overflow: ellipsis;*/
  white-space: nowrap;
  flex-grow: 1;
  transition: color 0.3s;
  font-weight: 600;
}

.layout .sidebar .menu .menu-item a .menu-prefix,
.layout .sidebar .menu .menu-item a .menu-suffix {
  display: inline-block;
  padding: 5px;
  opacity: 1;
  transition: opacity 0.3s;
}

.layout .sidebar .menu .menu-item a:hover .menu-title {
  color: var(--white);
}

.layout .sidebar .menu .menu-item a:hover .menu-icon {
  color: rgb(45, 112, 138);
}





.layout .sidebar .menu .menu-item a:hover .menu-icon i {
  animation: swing ease-in-out 0.5s 1 alternate;
}

.layout .sidebar .menu .menu-item a:hover::after {
  border-color: #FFF !important;
}

.layout .sidebar .menu .menu-item.sub-menu {
  position: relative;
}

.layout .sidebar .menu .menu-item.sub-menu>a::after {
  content: '';
  transition: transform 0.3s;
  border-right: 2px solid currentcolor;
  border-bottom: 2px solid currentcolor;
  width: 5px;
  height: 5px;
  transform: rotate(-45deg);
}

.layout .sidebar .menu .menu-item.sub-menu>.sub-menu-list {
  display: none;
  overflow: hidden;
  z-index: 999;
}

.layout .sidebar .menu .menu-item.sub-menu.open ul .menu-item a {
  padding-left: 39px;
  color: var(--main-color);
}

.layout .sidebar .menu .menu-item.sub-menu>a {
  color: var(--main-color);
  background-color: orange;
}

.layout .sidebar .menu .menu-item.sub-menu>a::after {
  transform: rotate(45deg);
}

.layout .sidebar .menu .menu-item.active>a {
  color: #fff;
  background: #3DC8AF;
}

.layout .sidebar .menu .menu-item.active>a .menu-title {
  color: #fff;
}

.layout .sidebar .menu .menu-item.active>a::after {
  border-color: #fff;
}

.layout .sidebar .menu .menu-item.active>a .menu-icon {
  color: #fff;
}

.layout .sidebar .menu>ul>.sub-menu>.sub-menu-list {
  /* background-color: white;*/
}

.layout .sidebar .menu>ul>.sub-menu>.sub-menu-list ul {
  background-color: white;
  margin-left: 12px;
  margin-right: 12px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.layout .sidebar .menu.icon-shape-circle .menu-item a .menu-icon,
.layout .sidebar .menu.icon-shape-rounded .menu-item a .menu-icon,
.layout .sidebar .menu.icon-shape-square .menu-item a .menu-icon {
  background-color: #0b1a2c;
}

.layout .sidebar .menu.icon-shape-circle .menu-item a .menu-icon {
  border-radius: 50%;
}

.layout .sidebar .menu.icon-shape-rounded .menu-item a .menu-icon {
  border-radius: 4px;
}

.layout .sidebar .menu.icon-shape-square .menu-item a .menu-icon {
  border-radius: 0;
}

.layout .sidebar:not(.collapsed) .menu>ul>.menu-item.sub-menu>.sub-menu-list {
  visibility: visible !important;
  position: static !important;
  transform: translate(0, -7px) !important;
}

.layout .sidebar.collapsed .menu>ul>.menu-header {
  opacity: 0;
}

.layout .sidebar.collapsed .menu>ul>.menu-item>a .menu-prefix,
.layout .sidebar.collapsed .menu>ul>.menu-item>a .menu-suffix {
  opacity: 0;
}

.layout .sidebar.collapsed .menu>ul>.menu-item.sub-menu>a::after {
  content: '';
  width: 5px;
  height: 5px;
  background-color: currentcolor;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 50%;
  border: none;
  transform: translateY(-50%);
}

.layout .sidebar.collapsed .menu>ul>.menu-item.sub-menu>a:hover::after {
  background-color: #dee2ec;
}

.layout .sidebar.collapsed .menu>ul>.menu-item.sub-menu>.sub-menu-list {
  transition: none !important;
  width: 200px;
  margin-left: 3px !important;
  border-radius: 4px;
  display: block !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.layout .sidebar.collapsed .menu>ul>.menu-item.active>a::after {
  background-color: #dee2ec;
}

.layout .sidebar.has-bg-image .menu.icon-shape-circle .menu-item a .menu-icon,
.layout .sidebar.has-bg-image .menu.icon-shape-rounded .menu-item a .menu-icon,
.layout .sidebar.has-bg-image .menu.icon-shape-square .menu-item a .menu-icon {
  background-color: rgba(11, 26, 44, 0.6);
}

.layout .sidebar.has-bg-image:not(.collapsed) .menu>ul>.sub-menu>.sub-menu-list {
  /* background-color: var(--white);*/
}

.layout.rtl .sidebar .menu .menu-item a .menu-icon {
  margin-left: 10px;
  margin-right: 0;
}

.layout.rtl .sidebar .menu .menu-item.sub-menu>a::after {
  transform: rotate(135deg);
}

.layout.rtl .sidebar .menu .menu-item.sub-menu>.sub-menu-list {
  padding-left: 0;
  padding-right: 20px;
}

.layout.rtl .sidebar .menu .menu-item.sub-menu.open>a::after {
  transform: rotate(45deg);
}

.layout.rtl .sidebar.collapsed .menu>ul>.menu-item.sub-menu a::after {
  right: auto;
  left: 10px;
}

.layout.rtl .sidebar.collapsed .menu>ul>.menu-item.sub-menu>.sub-menu-list {
  margin-left: -3px !important;
}

.layout .sidebar .pro-sidebar-logo {
  display: flex;
  align-items: center;
}

.layout .sidebar .pro-sidebar-logo>div {
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background: linear-gradient(271.86deg, #0092D0 0.13%, #00519C 97.84%);
  margin-right: 10px;
}

.layout .sidebar .pro-sidebar-logo>h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 30px;
  transition: opacity 0.3s;
  opacity: 1;
}

.layout .sidebar .footer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.8em;
  padding: 20px 0;
  border-radius: 8px;
  width: 180px;
  min-width: 190px;
  margin: 0 auto;
  background-color: #162d4a;
}

.layout .sidebar .footer-box img.react-logo {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.layout .sidebar .footer-box a {
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
}

.layout .sidebar .sidebar-collapser {
  transition: left, right, 0.3s;
  position: fixed;
  left: 260px;
  top: 40px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--main-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  transform: translateX(50%);
  z-index: 111;
  cursor: pointer;
  color: white;
  box-shadow: 1px 1px 4px #3DC8AF;
}

.layout .sidebar.collapsed .pro-sidebar-logo>h5 {
  opacity: 0;
}

.layout .sidebar.collapsed .footer-box {
  display: none;
}

.layout .sidebar.collapsed .sidebar-collapser {
  left: 60px;
}

.layout .sidebar.collapsed .sidebar-collapser i {
  transform: rotate(180deg);
}

.sidebar-toggler {
  position: fixed;
  right: 20px;
  top: 20px;
}


.drop-down-style {
  /* background-color: white; */
  padding: 0px;
  color: #3DB7A55E;
  margin: 0px 0px;
  text-decoration: none;
}


.layout .sidebar .menu .submenu-item a {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 12px;
  color: #3db7a5;
  margin: 0px 0px;
  text-decoration: none;
  border-radius: 10px;
}



.layout .sidebar .menu .submenu-item-wo-hover a {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0px 12px;
  color: #3DB7A5;
  background-color: #d0f3ed;
  margin: 0px 0px;
  text-decoration: none;
  font-weight: bold;

}


/************* MEDIA QUESTIES *************/


@media (max-width: 1600px) {
  .layout .sidebar.break-point-xxl {
    position: fixed;
    left: -280px;
    height: 100%;
    top: 0;
    z-index: 100;
  }

  .layout .sidebar.break-point-xxl.collapsed {
    left: -80px;
  }

  .layout .sidebar.break-point-xxl.toggled {
    left: 0;
  }

  .layout .sidebar.break-point-xxl.toggled~.overlay {
    display: block;
  }

  .layout .sidebar.break-point-xxl~.layout .header {
    width: 100% !important;
    -webkit-transition: none;
    transition: none;
  }

  .layout .sidebar-toggler.break-point-xxl {
    display: initial;
  }

  .layout.rtl .sidebar.break-point-xxl {
    left: auto;
    right: -280px;
  }

  .layout.rtl .sidebar.break-point-xxl.collapsed {
    left: auto;
    right: -80px;
  }

  .layout.rtl .sidebar.break-point-xxl.toggled {
    left: auto;
    right: 0;
  }
}


@media (max-width: 1200px) {
  .layout .sidebar.break-point-xl {
    position: fixed;
    left: -280px;
    height: 100%;
    top: 0;
    z-index: 100;
  }

  .layout .sidebar.break-point-xl.collapsed {
    left: -80px;
  }

  .layout .sidebar.break-point-xl.toggled {
    left: 0;
  }

  .layout .sidebar.break-point-xl.toggled~.overlay {
    display: block;
  }

  .layout .sidebar.break-point-xl~.layout .header {
    width: 100% !important;
    -webkit-transition: none;
    transition: none;
  }

  .layout .sidebar-toggler.break-point-xl {
    display: initial;
  }

  .layout.rtl .sidebar.break-point-xl {
    left: auto;
    right: -280px;
  }

  .layout.rtl .sidebar.break-point-xl.collapsed {
    left: auto;
    right: -80px;
  }

  .layout.rtl .sidebar.break-point-xl.toggled {
    left: auto;
    right: 0;
  }
}


@media (min-width: 1024px) and (max-width: 1200px) {
  .layout .nav-item .nav-link {
    margin: 0px !important;
  }

  .d-None .bg-login {
    align-items: normal;
  }

  .d-None .bg-login img.img-fluid {
    width: 100% !important;
    object-fit: cover;

  }
}


@media (max-width: 992px) {
  .layout .sidebar.break-point-lg {
    position: fixed;
    left: -280px;
    height: 100%;
    top: 0;
    z-index: 100;
  }

  .layout .sidebar.break-point-lg.collapsed {
    left: -80px;
  }

  .layout .sidebar.break-point-lg.toggled {
    left: 0;
  }

  .layout .sidebar.break-point-lg.toggled~.overlay {
    display: block;
  }

  .layout .sidebar.break-point-lg~.layout .header {
    width: 100% !important;
    -webkit-transition: none;
    transition: none;
  }

  .layout .sidebar-toggler.break-point-lg {
    display: initial;
  }

  .layout.rtl .sidebar.break-point-lg {
    left: auto;
    right: -280px;
  }

  .layout.rtl .sidebar.break-point-lg.collapsed {
    left: auto;
    right: -80px;
  }

  .layout.rtl .sidebar.break-point-lg.toggled {
    left: auto;
    right: 0;
  }

  #btn-collapse {
    display: none;
  }
}


@media (min-width:768px) and (max-width: 1023px) {
  .border-start {
    border-left: 0px !important;
  }

  .nav-item+.nav-item::before {
    display: none;
  }

  .bg-login {
    background-repeat: repeat-x;
  }

  .input-group.br {
    margin-left: 0px;
  }

  .community-dropdown {
    padding: 8px 8px;
    margin-right: 0px;
    margin-left: 15px;
  }

  .profile-img {
    width: 20px;
    height: 20px;
  }

  table {
    white-space: nowrap;
  }
}


@media (max-width: 768px) {
  .layout .sidebar.break-point-md {
    position: fixed;
    left: -280px;
    height: 100%;
    top: 0;
    z-index: 100;
  }

  .layout .sidebar.break-point-md.collapsed {
    left: -80px;
  }

  .layout .sidebar.break-point-md.toggled {
    left: 0;
  }

  .layout .sidebar.break-point-md.toggled~.overlay {
    display: block;
  }

  .layout .sidebar.break-point-md~.layout .header {
    width: 100% !important;
    -webkit-transition: none;
    transition: none;
  }

  .layout .sidebar-toggler.break-point-md {
    display: initial;
  }

  .layout.rtl .sidebar.break-point-md {
    left: auto;
    right: -280px;
  }

  .layout.rtl .sidebar.break-point-md.collapsed {
    left: auto;
    right: -80px;
  }

  .layout.rtl .sidebar.break-point-md.toggled {
    left: auto;
    right: 0;
  }
}


@media (max-width: 767px) {
  #btn-collapse {
    display: block !important;
  }

  .layout .sidebar.break-point-sm.collapsed {
    left: 0px !important;
  }

  .layout .sidebar .sidebar-collapser {
    left: 36px !important;
    top: 15px !important;
    width: 25px;
    height: 25px;
  }

  .border-start {
    border-left: 0px !important;
  }

  .layout .sidebar.collapsed .sidebar-collapser {
    left: 60px !important;
  }

  .nav-item+.nav-item::before {
    display: none;
  }

  .d-None {
    display: none;
  }

  .border-0.common .card-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  .layout .navbar-nav.ms-auto {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: auto !important;
    margin-top: 15px !important;
  }

  .input-group.br {
    margin-left: 0px;
  }

  table {
    white-space: nowrap;
  }

  .navbar-toggler {
    padding: 0px;
    border: 0px;
  }
}


@media (max-width: 576px) {
  .layout .sidebar.break-point-sm {
    position: fixed;
    left: -280px;
    height: 100%;
    top: 0;
    z-index: 100;
  }

  .layout .sidebar.break-point-sm.collapsed {
    left: -80px;
  }

  .layout .sidebar.break-point-sm.toggled {
    left: 0;
  }

  .layout .sidebar.break-point-sm.toggled~.overlay {
    display: block;
  }

  .layout .sidebar.break-point-sm~.layout .header {
    width: 100% !important;
    -webkit-transition: none;
    transition: none;
  }

  .layout .sidebar-toggler.break-point-sm {
    display: initial;
  }

  .layout.rtl .sidebar.break-point-sm {
    left: auto;
    right: -280px;
  }

  .layout.rtl .sidebar.break-point-sm.collapsed {
    left: auto;
    right: -80px;
  }

  .layout.rtl .sidebar.break-point-sm.toggled {
    left: auto;
    right: 0;
  }
}


@media (max-width: 480px) {
  .layout .sidebar.break-point-xs {
    position: fixed;
    left: -280px;
    height: 100%;
    top: 0;
    z-index: 100;
  }

  .layout .sidebar.break-point-xs.collapsed {
    left: -80px;
  }

  .layout .sidebar.break-point-xs.toggled {
    left: 0;
  }

  .layout .sidebar.break-point-xs.toggled~.overlay {
    display: block;
  }

  .layout .sidebar.break-point-xs~.layout .header {
    width: 100% !important;
    -webkit-transition: none;
    transition: none;
  }

  .layout .sidebar-toggler.break-point-xs {
    display: initial;
  }

  .layout.rtl .sidebar.break-point-xs {
    left: auto;
    right: -280px;
  }

  .layout.rtl .sidebar.break-point-xs.collapsed {
    left: auto;
    right: -80px;
  }

  .layout.rtl .sidebar.break-point-xs.toggled {
    left: auto;
    right: 0;
  }
}



/* New */

#root {
  height: 100%;
  min-height: 100vh;
}

.table>:not(:first-child) {
  border: none;
}

.btn-send {
  background-color: white;
  color: #3DB7A5;
  font-weight: 600;
  border: 1px solid #3db7a5;
}

.hover_image:hover {
  text-decoration: none
}

.hide {
  display: none
}

.hover_image:hover img:first-child {
  display: none
}

.hover_image:hover img:last-child {
  display: inline-block
}


.drop-down-style .submenu-item-wo-hover a .menu-icon {
  width: 35px;
  min-width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border-radius: 2px;
  transition: color 0.3s;
}


svg {
  float: right;
  margin-top: 5px;
  stroke-width: 1px;
}


.page-item.active .page-link {
  background: #3DB7A5;
  border: 1px solid #3DB7A5;
  padding: 8px 15px;
}


.page-item .page-link {
  color: #3DB7A5;
  border: none;
  padding: 8px 15px;
  font-size: 15px;
  font-weight: bold;
  margin-top: 15px;

}


.form-check.form-switch {
  margin-top: 8px;
}

.form-check-input:checked {
  background-color: #3DB7A5;
  border-color: #3DB7A5;

}

.form-check-input:focus {
  box-shadow: none;
}

.btn:focus {
  box-shadow: none;
}

.menu-title {
  width: 100%;
}

.submenu-item-wo-hover {
  margin-bottom: 5px;
}


.menu-item-wo-hover {
  background-color: white;
  margin: 0px 12px;
  border-radius: 10px;
}

.fw-semibold {
  font-weight: 600;
  color: #8f8f8f;
}

.layout .sidebar .menu .submenu-item-wo-hover a:hover {
  text-decoration: underline;
  text-underline-offset: 4px;

}

.layout .sidebar .menu .submenu-item a:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.btn-primary {
  background-color: var(--main-color);
  border: none;
}

.btn-primary:hover {
  background-color: var(--main-color);
  border: none;
}


/* .layout .sidebar .menu .submenu-item-wo-hover a:hover .menu-title {
  color: var(--white);
} */